<template>
    <div>
        <Quote/>
        <GoBack next="Promo kod yaratish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-md-8">
                <form class="ABC" @submit.prevent="addPromoCode; promoGenerate">
                    <label-component label-for="promo" class="mb-1">Promo kod yarating</label-component>
                    <div class="mb-3 d-flex gap-2">
                        <input-component
                            model-name="promo"
                            type="text"
                            id="promo"
                            :placeholder="'Promokod yarating'"
                            :model-form="addPromo"
                            @change="checkHandlePromo"
                        />
                        <button-component
                            @click="checkPromo"
                            :class="'col-4 col-md-2 p-2'"
                        >
                            Yaratish
                        </button-component>
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Qaysi kurs turi uchun?</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="addPromo.promos"
                            multiple
                            :select-size="5"
                            required
                        >
                            <option
                                v-for="courseType of getCourseTypes"
                                :key="courseType.id"
                                :label="courseType.name"
                                :value="'/api/course_types/' + courseType.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <label-component label-for="until" class="mb-1">Amal qilish muddati</label-component>
                    <div class="mb-3 d-flex">
                        <input-component
                            model-name="until"
                            type="date"
                            id="until"
                            :model-form="addPromo"
                            required
                        />
                    </div>
                    <label for="discount" class="form-label">Chegirma</label>
                    <div class="mb-3 d-flex">

                        <b-form-input
                            id="discount"
                            class="form-control form-control-input col-7 col-sm-8 col-md-9"
                            placeholder="Chegirma foizi yoki summasi"
                            v-model="addPromo.discount"
                            :formatter="formatNumber"
                        >

                        </b-form-input>
                        <select
                            v-model="addPromo.isPercent"
                            class="form-select form-control-select col-5 col-sm-4 col-md-3"
                            required
                        >
                            <option value="" selected>Turini tanlang</option>
                            <option :value="true">Foiz</option>
                            <option :value="false">Summa</option>
                        </select>
                    </div>

                    <label-component label-for="amount" class="mb-1">Miqdori</label-component>
                    <div class="mb-3 d-flex">
                        <input-component
                            model-name="amount"
                            type="number"
                            :placeholder="'Miqdori'"
                            id="amount"
                            :model-form="addPromo"
                            required
                        />
                    </div>
                    <div>
                        <b-modal ref="modal-unsuccess" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ushbu promokod ma'lumotlar bazasida mavjud. Iltimos qaytadan yarating</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px" variant="btn btn-primary"
                                size="md"
                                @click="errorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <div class="mb-3 col-12 col-md-5 col-lg-4 col-xl-3">
                            <button-component
                                :disabled="!addPromo.promo || !addPromo.amount || !addPromo.discount || !addPromo.until || !this.getCheckPromo"
                                @click="addPromoCode"
                            >
                                Saqlash
                            </button-component>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-success1" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Promokod muvaffaqiyatli qo'shildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal; $router.push('/promo-codes')"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";
import accessMixin from "@/mixin/accessMixin";
import ButtonComponent from "@/components/ButtonComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import InputComponent from "@/components/InputComponent.vue";

export default {
    name: "Add-CoursePage",
    mixins: [accessMixin],
    components: {ButtonComponent, GoBack, Quote, LabelComponent, InputComponent},
    data() {
        return {
            addPromo: {
                promo: '',
                discount: 0,
                until: '',
                isPercent: false,
                amount: 100,
                promos: []
            },
            show: true,
            promoId: null
        }
    },
    computed: {
        ...mapGetters([
            'getCheckPromo',
            'getPromoCodesTotal',
            'getPromo',
            'getCourseTypes',
            'getPromoCodes',
            'getSearchPromo'
        ]),
    },
    methods: {
        ...mapActions([
            'fetchPromoCodes',
            'createPromoCode',
            'checkPromoCode',
            'fetchCourseTypes',
            'deletePromoCode',
            'searchPromo'
        ]),
        promoGenerate() {
            const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';

            function generateString(length) {
                let result = ' ';
                const charactersLength = characters.length;
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result;
            }

            this.addPromo.promo = (generateString(6).trim());
        },
        checkPromo() {
            this.promoGenerate()
            this.checkPromoCode(
                {
                    promo: this.addPromo.promo,
                }
            )
                .then(() => {
                    if (!this.getCheckPromo.isUnique) {
                        this.$refs['modal-unsuccess'].show()
                        this.addPromo.promo = ""
                    }
                })
        },
        checkHandlePromo() {
            this.checkPromoCode(
                {
                    promo: this.addPromo.promo,
                }
            )
                .then(() => {
                    if (!this.getCheckPromo.isUnique) {
                        this.$refs['modal-unsuccess'].show()
                        this.addPromo.promo = ""
                    }
                })

        },
        addPromoCode() {
            if (this.getCheckPromo.isUnique) {
                let discount = this.addPromo.discount + '00'
                discount.replace(/\s/g, "")
                let data = {
                    promo: this.addPromo.promo,
                    discount: parseInt(discount.replace(/\s/g, "")),
                    until: this.addPromo.until,
                    isPercent: this.addPromo.isPercent,
                    amount: parseInt(this.addPromo.amount),
                    promos: this.addPromo.promos
                }
                this.createPromoCode(data)
                    .then(() => {
                        this.$refs['modal-success1'].show()
                    })
            }
        },
        hideErrorModal() {
            this.$refs['modal-success1'].hide()
        },
        errorModal() {
            this.$refs['modal-unsuccess'].hide()
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
    },
    mounted() {
        this.show = true
        this.fetchCourseTypes()
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

.btn-promo {
    margin-left: 3px;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #80007F;
    color: #FFFFFF;
    border: 0;
}


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-size: 25px !important;
}

select:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

select:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

select::-ms-expand {
    display: none !important;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.mb-3 .form-control-select {
    border-radius: 0 10px 10px 0;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.mb-3 .form-control-input {
    border-radius: 10px 0 0 10px;
    border: solid #80007F 1px;
    border-right: none;
    font-family: Comfortaa, cursive;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;
}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}

img {
    width: 20px;
    height: 20px;
}

input {
    border: 1px solid black;
    padding-left: 0.5rem;
}

.input::placeholder {
    font-size: 13px;
    padding: 0.5rem;
}

@media (max-width: 450px) {
    .texts {
        word-break: break-all;
        font-size: 13px;
    }

    .input {
        font-size: 4vw;
        margin: 0;
    }
}
</style>
